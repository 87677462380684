@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 2em;
}

body,h1,h2,h3,h4,h5,h6,.luna-button {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.service-card {
  display: inline-block !important;
  margin: 5px;
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
}

.App-link {
  color: #61dafb;
}

.Service-card {
  max-width: 400px;
}

.Processes-card {
  max-width: 1000px;
}

.service-card__container {
  min-height: 225px;
}

.luna-card--border .luna-card__actions {
  width: 50%;
  margin: auto;
}

.service-card__desc {
  min-height: 60px;
}
